
export class LanguagesToggler {

	wrapper: HTMLElement;
	trigger: HTMLElement;

	constructor(wrapper: HTMLElement) {
		this.wrapper = wrapper;
		this.trigger = this.wrapper.querySelector('.active');

		this.trigger.addEventListener('click', (ev: MouseEvent) => {
			ev.preventDefault();

			this.toggleLangs();
		});
	}


	toggleLangs() {
		if ( this.wrapper.classList.contains('shown') ) {
			this.wrapper.classList.remove('shown');
		} else {
			this.wrapper.classList.add('shown');
		}
	}

}