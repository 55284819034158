
export class Menu {

	trigger: HTMLButtonElement;
	wrapper: HTMLElement;

	constructor() {
		this.wrapper = document.querySelector('nav');
		this.trigger = document.querySelector('.menu-btn');

		this.trigger.addEventListener('click', () => {
			this.toggleMenu();
		});
	}


	toggleMenu() {
		if ( this.trigger.classList.contains('active') ) {
			this.closeMenu();
		} else {
			this.openMenu();
		}
	}


	closeMenu() {
		this.wrapper.classList.remove('active');
		this.trigger.classList.remove('active');
		document.body.classList.remove('menu-open');
	}

	openMenu() {
		this.wrapper.classList.add('active');
		this.trigger.classList.add('active');
		document.body.classList.add('menu-open');
		this.wrapper.scrollIntoView();
	}

}

