import '../scss/btf/btf.scss';

import { Menu } from './components/menu/menu';
import { LanguagesToggler } from "./components/languages-toggler/languages-toggler";

document.addEventListener(
	'DOMContentLoaded',
	() => {

		if (document.querySelector('.menu-btn')) {
			new Menu();
		}

		if (document.querySelector('.languages-toggler')) {
			new LanguagesToggler( document.querySelector('.languages-toggler') );
		}

		if (document.querySelector('.page-template-home')) {
			import('./pages/home/home').then(c => new c.Home());
		} else if (document.querySelector('.page-template-company')) {
			import('./pages/company/company').then(c => new c.Company());
		} else if (document.querySelector('.page-template-news-list')) {
			import('./pages/news-list/news-list').then(c => new c.NewsList());
		} else if (document.querySelector('.page-template-regulations')) {
			import('./pages/regulations/regulations').then(c => new c.Regulations());
		} else if (document.querySelector('.page-template-product-detail')) {
			import('./pages/product-detail/product-detail').then(c => new c.ProductDetail());
		} else if (document.querySelector('.page-template-catalogs')) {
			import('./pages/catalogs/catalogs').then((c) => new c.Catalogs());
		} else if (document.querySelector('.page-template-contacts')) {
			import('./pages/contacts/contacts').then((c) => new c.Contacts());
		} else if (document.querySelector('.page-template-register')) {
			import('./pages/register/register').then((c) => new c.Register());
		} else {
			import('./pages/page').then((c) => {
				const p = new c.Page();
				p.pageSetup();
				p.pageReady();
			});
		}
	},
	false
);


